import { useState } from 'react'
import { DefaultErrorMessage } from '../utils/error-util.js'
import apiConfig from '../apiConfig.js'
import { createMobileBadge } from '../api/mobileBadgeActivation.js'

export default function useCreateMobileBadge(session) {
  const [createMobileBadgeComplete, setCreateMobileBadgeComplete] =
    useState(false)
  const [isLoadingcreateMobileBadge, setIsLoadingcreateMobileBadge] =
    useState(false)
  const [createMobileBadgeError, setCreateMobileBadgeErrorError] = useState('')
  const [stopCall, setStopCall] = useState(false)
  const createMobileBadges = async () => {
    setIsLoadingcreateMobileBadge(true)
    setStopCall(true)
    const response = await createMobileBadge(
      apiConfig.apiUrl,
      apiConfig.apiKey,
      session?.userInfo?.lanId,
    )

    if (response?.data) {
      setCreateMobileBadgeComplete(true)
      setIsLoadingcreateMobileBadge(false)
      setStopCall(false)
    }
    if (response.data === null) {
      setCreateMobileBadgeComplete(true)
      setIsLoadingcreateMobileBadge(false)
      setStopCall(false)
    }
    if (response?.error) {
      setIsLoadingcreateMobileBadge(false)
      setCreateMobileBadgeComplete(false)
      setCreateMobileBadgeErrorError(DefaultErrorMessage)
      setStopCall(false)
      return null
    }
    setIsLoadingcreateMobileBadge(false)
  }

  return {
    createMobileBadges,
    isLoadingcreateMobileBadge,
    createMobileBadgeComplete,
    createMobileBadgeError,
    stopCall,
    setCreateMobileBadgeErrorError,
  }
}
