import { Grid } from '@enterprise-ui/canvas-ui-react'
import { InfoRow } from './infoRow.js'
import checkMark from '../utils/images/checkMark.png'

const PermanentBadgeInfo = () => {
  return (
    <Grid.Container justify="center">
      <InfoRow
        icon={checkMark}
        altIcon="checkmark affirming success of badge reactivation"
        text="your badge has been activated"
      />
    </Grid.Container>
  )
}

export { PermanentBadgeInfo }
