import { Grid } from '@enterprise-ui/canvas-ui-react'
import { InfoRow } from './infoRow.js'
import exclamationMark from '../utils/images/exclamation-mark.png'
const DownloadProcess = () => {
  return (
    <Grid.Container justify="center">
      {/* <InfoRow
        icon={checkMark}
        altIcon="your mobile badge has activated"
        text="your mobile badge has been activated"
      /> */}
      <InfoRow
        icon={exclamationMark}
        altIcon="exclamation mark informing user to check email"
        text="please follow the instructions sent to your email to continue setup for Genea Mobile Access application​ 
        email will have an [EXTERNAL] tag from Genea Support but is legitimate"
      />
    </Grid.Container>
  )
}

export { DownloadProcess }
