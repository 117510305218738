import QRCodeReact from 'qrcode.react'

const TemporaryBadgeQRCode = (props) => {
  return (
    <QRCodeReact
      bgColor="#E6E7EA"
      fgColor="#CC0100"
      className="qrCode"
      value={props.badgeNumber}
      renderAs="svg"
      height="45%"
      width="45%"
      level="H"
      includeMargin={true}
      marginSize={4} //not implemented yet, but will be in next major version of QRCode.react
    />
  )
}

export { TemporaryBadgeQRCode }
