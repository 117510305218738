import './Main.css'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Home.js'
import { ProtectedElement } from '@praxis/component-auth'
import { HelveticaForTarget } from '@enterprise-ui/component-font'

export default function Main() {
  return (
    <React.Fragment>
      <HelveticaForTarget />
      <Routes>
        {/* Some use cases require a route to be available to everyone: */}
        <Route
          index
          element={
            <ProtectedElement>
              <Home />
            </ProtectedElement>
          }
        />
      </Routes>
    </React.Fragment>
  )
}
