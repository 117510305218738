import { useState } from 'react'
import { DefaultErrorMessage } from '../utils/error-util.js'
import apiConfig from '../apiConfig.js'
import { reactivateMobileBadges } from '../api/reactivateMobileBadges.js'

export default function useReactivateMobileBadge(session) {
  const [reactivateMobileBadgeComplete, setReactivateMobileBadgeComplete] =
    useState()
  const [isLoadingReactivateMobileBadge, setIsLoadingReactivateMobileBadge] =
    useState(false)
  const [reactivateMobileBadgeError, setReactivateMobileBadgeError] =
    useState('')

  const reactivateMobileBadge = async () => {
    setIsLoadingReactivateMobileBadge(true)
    const response = await reactivateMobileBadges(
      apiConfig.apiUrl,
      apiConfig.apiKey,
      session?.userInfo?.lanId,
    )
    setIsLoadingReactivateMobileBadge(false)
    if (response?.error) {
      setReactivateMobileBadgeError(DefaultErrorMessage)
      return null
    }
    setReactivateMobileBadgeComplete(true)
  }

  return {
    reactivateMobileBadge,
    reactivateMobileBadgeComplete,
    isLoadingReactivateMobileBadge,
    reactivateMobileBadgeError,
    setReactivateMobileBadgeError,
  }
}
