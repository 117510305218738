import { Button, Grid, Image } from '@enterprise-ui/canvas-ui-react'

const HomePageButton = (props) => {
  return (
    <Grid.Item className="homePageButtonDiv" spacing="none">
      <Button
        className="homePageButton"
        name="generateBadge"
        size="lg"
        onClick={props.onClick}
      >
        <Grid.Container
          align="center"
          direction="row"
          spacing="none"
          justify="center"
        >
          <Image
            height="12vh"
            width="12vh"
            src={props.icon}
            alt={props.iconAlt}
          />
          <Grid.Item className="homePageButtonTextDiv" xs={8}>
            {props.text}
          </Grid.Item>
        </Grid.Container>
      </Button>
    </Grid.Item>
  )
}

export { HomePageButton }
