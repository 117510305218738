import { Grid } from '@enterprise-ui/canvas-ui-react'
import questionMark from '../utils/images/questionMark.png'
const DefaultErrorModal = () => {
  return (
    <Grid.Container justify="center">
      <Grid.Item xs={3}>
        <img
          src={questionMark}
          alt="Question mark icon indicating more information needed"
          width="80%"
        />
      </Grid.Item>
      <Grid.Item xs={7}>
        <p className="modalText">
          please proceed to the security desk or call: <br />
          <br />
          612-761-1111 (US) <br />
          or <br />
          91-80-4222-6111 (Asia) <br /> <br />
          so we can assist you further with your request
        </p>
      </Grid.Item>
    </Grid.Container>
  )
}

export default DefaultErrorModal
