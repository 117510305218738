import Axios from 'axios'

export const reactivatePermanentBadge = async (APIurl, apiKey, lanId) => {
  // TODO: add specific key for visitorPortal
  const endpoint = `${APIurl}permanent_badges/reactivate?key=${apiKey}`
  try {
    const response = await Axios.put(endpoint, {
      lan_id: lanId,
    })
    return response?.data
  } catch (e) {
    return {
      error: true,
    }
  }
}
