import { Grid } from '@enterprise-ui/canvas-ui-react'

const InfoRow = (props) => {
  return (
    <Grid.Item xs={10}>
      <Grid.Container align="flex-start" justify="flex-start" noWrap={true}>
        <Grid.Item className="icondiv">
          <img src={props.icon} alt={props.iconAlt} />
        </Grid.Item>
        <Grid.Item
          style={{
            width: '100%',
          }}
        >
          <div className="InfoRowDiv">{props.text}</div>
        </Grid.Item>
      </Grid.Container>
    </Grid.Item>
  )
}

export { InfoRow }
