import { Grid } from '@enterprise-ui/canvas-ui-react'

const CardholderNotice = () => {
  return (
    <Grid.Item xs={10}>
      <Grid.Container direction="row" align="center" justify="center">
        <Grid.Item className="noteDiv">
          note: cardholders are limited to one active badge-type at any time.
        </Grid.Item>
      </Grid.Container>
    </Grid.Item>
  )
}

export { CardholderNotice }
