import apiConfig from '../apiConfig.js'
import { useState } from 'react'
import { DefaultErrorMessage } from '../utils/error-util.js'
import { mobileBadgeActivation } from '../api/mobileBadgeActivation.js'
import { deleteBadgeBadge } from '../api/deleteMobileBadge.js'

export default function useMobileBadgeActivation(session) {
  const [mobileBadgeLoading, setMobileBadgeLoading] = useState(false)
  const [mobileBadgeError, setMobileBadgeError] = useState('')
  const [mobileBadge, setMobileBadge] = useState(false)

  const activateMobile = async () => {
    setMobileBadgeLoading(true)
    const response = await mobileBadgeActivation(
      apiConfig.apiUrl,
      apiConfig.apiKey,
      session?.userInfo?.lanId,
    )
    if (response?.error) {
      setMobileBadgeError(DefaultErrorMessage)
      return null
    }
    setMobileBadgeLoading(false)
  }

  const reactivateMobile = async () => {
    setMobileBadgeLoading(true)
    const responseDelete = await deleteBadgeBadge(
      apiConfig.apiUrl,
      apiConfig.apiKey,
      session?.userInfo?.lanId,
    )
    if (responseDelete?.error) {
      setMobileBadgeError(DefaultErrorMessage)
      return null
    }
    const response = await mobileBadgeActivation(
      apiConfig.apiUrl,
      apiConfig.apiKey,
      session?.userInfo?.lanId,
    )
    if (response?.error) {
      setMobileBadgeError(DefaultErrorMessage)
      return null
    }
    setMobileBadgeLoading(false)
  }

  return {
    activateMobile,
    reactivateMobile,
    mobileBadgeError,
    mobileBadgeLoading,
    setMobileBadge,
    mobileBadge,
  }
}
