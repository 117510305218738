import { useState } from 'react'
import { Modal, Spinner } from '@enterprise-ui/canvas-ui-react'
import useCreateDailyIssuanceBadge from './hooks/useCreateDailyIssuanceBadge.js'
import useMobileBadgeActivation from './hooks/useMobileBadgeActivation.js'
import TruCredHeader from './components/truCredHeader.js'
import DefaultErrorModal from './components/defaultErrorModal.js'
import { DefaultErrorMessage } from './utils/error-util.js'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { ReactivatePermanentBadgeButton } from './components/reactivatePermanentBadgeButton.js'
import { CreateTemporaryBadgeButton } from './components/createTemporaryBadgeButton.js'
import { CardholderNotice } from './components/cardholderNotice.js'
import { TemporaryBadgeInfo } from './components/temporaryBadgeInfo.js'
import { TemporaryBadgeQRCode } from './components/temporaryBadgeQRCode.js'
import useReactivatePermanentBadge from './hooks/useReactivatePermanentBadge.js'
import useReactivateMobileBadge from './hooks/useReactivateMobileBadge.js'
import { PermanentBadgeInfo } from './components/permanentBadgeInfo.js'
import { UserNameDisplay } from './components/userNameDisplay.js'
import { useAuth } from '@praxis/component-auth'
import { DigitalBadgeButton } from './components/digitalBadgeButton.js'
import MobileBadge from './components/MobileBadge.js'
import useOnLoadMobileBadge from './hooks/useOnLoadMobileBadge.js'
import UserHasMobileBadgeActive from './components/userHasMobileBadgeActive.js'
import useCreateMobileBadge from './hooks/useCreateMobileBage.js'

export default function Home() {
  const { session } = useAuth()
  /* converts email into string, removes '@target.com' to get userName */
  const userName = String(session?.userInfo?.email).replace('@target.com', '')
  // const hasMobileCredADGroup =
  //   session?.userInfo?.memberOf.includes('APP-PAC00010874-PHYSICALACCESS') ||
  //   session?.userInfo?.memberOf.includes('APP-GBK-ADMINPROD')
  console.log(session?.userInfo?.memberOf)
  const [
    onClickErrorHandlerForUserLoadingMobileBadge,
    setOnClickErrorHandlerForUserLoadingMobileBadge,
  ] = useState(false)
  const {
    generateBadge,
    badgeNumber,
    isLoadingDailyIssuance,
    dailyIssuanceBadgeExpirationTime,
    dailyIssuanceError,
    setDailyIssuanceError,
  } = useCreateDailyIssuanceBadge(session)

  const {
    reactivateBadge,
    reactivateBadgeComplete,
    isLoadingReactivateBadge,
    reactivatePermanentBadgeError,
    setReactivatePermanentBadgeError,
  } = useReactivatePermanentBadge(session)

  const {
    reactivateMobileBadge,
    reactivateMobileBadgeComplete,
    isLoadingReactivateMobileBadge,
    reactivateMobileBadgeError,
    setReactivateMobileBadgeError,
  } = useReactivateMobileBadge(session)

  const { mobileBadgeLoading, mobileBadgeError, mobileBadge, setMobileBadge } =
    useMobileBadgeActivation(session)

  const {
    userHasMobileBadge,
    userHasMobileBadgeErrorFlag,
    setUserHasMobileBadgeErrorFlag,
    isLoadingUserHasMobileBadge,
    ldapAdList,
    isLdapLoading,
  } = useOnLoadMobileBadge(session)

  const {
    createMobileBadges,
    isLoadingcreateMobileBadge,
    createMobileBadgeComplete,
    createMobileBadgeError,
    stopCall,
    setCreateMobileBadgeErrorError,
  } = useCreateMobileBadge(session)

  let isLoading =
    isLoadingDailyIssuance ||
    isLoadingReactivateBadge ||
    isLoadingReactivateMobileBadge ||
    mobileBadgeLoading ||
    isLoadingUserHasMobileBadge ||
    isLoadingcreateMobileBadge ||
    isLdapLoading

  let error =
    dailyIssuanceError ||
    reactivatePermanentBadgeError ||
    reactivateMobileBadgeError ||
    mobileBadgeError ||
    createMobileBadgeError

  const handleUserBadgeClick = (e) => {
    if (userHasMobileBadgeErrorFlag === true) {
      setMobileBadge(false)
      setOnClickErrorHandlerForUserLoadingMobileBadge(true)
    } else {
      setMobileBadge(true)
    }
  }
  const hasMobileCredADGroup =
    ldapAdList.includes(
      'CN=APP-PAC00010874-PhysicalAccess,OU=Application,OU=Groupings,dc=corp',
    ) ||
    ldapAdList.includes(
      'CN=APP-GBK-ADMINPROD,OU=AdminLevel,OU=Groupings,dc=corp',
    )
  //For Production Release and debug
  console.log(ldapAdList, hasMobileCredADGroup)

  return (
    <>
      <div id="welcomeDiv" className="welcome">
        <div className="center">
          <TruCredHeader />
          {isLoading && <Spinner />}
          {!isLoading &&
            !badgeNumber &&
            !reactivateBadgeComplete &&
            !mobileBadge &&
            !reactivateMobileBadgeComplete && (
              <>
                <Grid.Container align="center" justify="center">
                  <Grid.Item>
                    <Grid.Container
                      align="flex-end"
                      direction="column"
                      spacing="none"
                    >
                      <CreateTemporaryBadgeButton onClick={generateBadge} />
                      <ReactivatePermanentBadgeButton
                        onClick={reactivateBadge}
                      />
                      {hasMobileCredADGroup &&
                        (userHasMobileBadge === 'InActiveMobileCred' ? (
                          <>
                            <DigitalBadgeButton
                              onClick={() => {
                                reactivateMobileBadge()
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <DigitalBadgeButton
                              onClick={() => {
                                handleUserBadgeClick()
                              }}
                            />
                          </>
                        ))}
                    </Grid.Container>
                  </Grid.Item>
                  <CardholderNotice />
                </Grid.Container>
              </>
            )}
          {badgeNumber && (
            <Grid.Container
              align="center"
              direction="column"
              justify="flex-start"
            >
              <UserNameDisplay userName={userName.toUpperCase()} />
              <TemporaryBadgeQRCode badgeNumber={badgeNumber} />
              <TemporaryBadgeInfo
                expirationTime={dailyIssuanceBadgeExpirationTime}
              />
            </Grid.Container>
          )}
          {reactivateBadgeComplete && (
            <>
              <UserNameDisplay userName={userName.toUpperCase()} />
              <PermanentBadgeInfo />
            </>
          )}

          {mobileBadge && userHasMobileBadge === 'Exists' && (
            <>
              <UserHasMobileBadgeActive />
            </>
          )}
          {mobileBadge && userHasMobileBadge === 'NotExists' && (
            <>
              <UserNameDisplay userName={userName.toUpperCase()} />
              <span className="mobileBadgeSpan" />
              <MobileBadge
                createMobileBadges={createMobileBadges}
                createMobileBadgeComplete={createMobileBadgeComplete}
                stopCall={stopCall}
                isLoadingcreateMobileBadge={isLoadingcreateMobileBadge}
              />
            </>
          )}

          {reactivateMobileBadgeComplete && (
            <>
              <UserNameDisplay userName={userName.toUpperCase()} />
              <PermanentBadgeInfo />
            </>
          )}

          <Modal
            headingText="MORE INFO NEEDED"
            isVisible={
              error === DefaultErrorMessage ||
              onClickErrorHandlerForUserLoadingMobileBadge
            }
            footer
            onRefuse={() => {
              setDailyIssuanceError('')
              setReactivatePermanentBadgeError('')
              setUserHasMobileBadgeErrorFlag(false)
              setOnClickErrorHandlerForUserLoadingMobileBadge(false)
              setReactivateMobileBadgeError('')
              setCreateMobileBadgeErrorError('')
            }}
          >
            <DefaultErrorModal />
          </Modal>
        </div>
      </div>
    </>
  )
}
