import QRcodePhone from '../utils/images/QRCodePhone.png'
import { HomePageButton } from './homePageButton.js'

const CreateTemporaryBadgeButton = (props) => {
  return (
    <HomePageButton
      text={'activate a temporary QR code'}
      icon={QRcodePhone}
      iconAlt="Phone icon with a QR code in the center"
      onClick={props.onClick}
    />
  )
}

export { CreateTemporaryBadgeButton }
