import { Grid } from '@enterprise-ui/canvas-ui-react'
import clock from '../utils/images/clock.png'
import checkMark from '../utils/images/checkMark.png'
import exclamationMark from '../utils/images/exclamation-mark.png'
import xMark from '../utils/images/xMark.png'
import { InfoRow } from './infoRow.js'

const TemporaryBadgeInfo = (props) => {
  return (
    <Grid.Container className="rowContainer" justify="center">
      <InfoRow
        icon={clock}
        altIcon="clock indicating expiration time for QR code."
        text={
          <>
            expires <br />
            <b className="extraBold">{props.expirationTime}</b>
          </>
        }
      />
      <InfoRow
        icon={checkMark}
        alt="check mark indicating locations accessible with this QR code"
        text="QR code allows access through lobby turnstiles only"
      />
      <InfoRow
        icon={exclamationMark}
        alt="exclamation mark indicating notice of plastic badge deactivation"
        text="all other badge types are now deactivated"
      />
      <InfoRow
        icon={xMark}
        iconAlt="x mark indicating incorrect use of QR code"
        text="do not share this QR code with other individuals"
      />
    </Grid.Container>
  )
}

export { TemporaryBadgeInfo }
