import DigitalBadge from '../utils/images/digitalBadge.png'
import { HomePageButton } from './homePageButton.js'

const DigitalBadgeButton = (props) => {
  return (
    <HomePageButton
      text={'activate a mobile badge'}
      icon={DigitalBadge}
      iconAlt="Phone icon with a Target logo the center"
      onClick={props.onClick}
    />
  )
}

export { DigitalBadgeButton }
