import Axios from 'axios'

export const mobileBadgeActivation = async (APIurl, apiKey, lanId) => {
  const endpoint = `${APIurl}mobile_badge?lan_id=${lanId}&key=${apiKey}`
  try {
    const response = await Axios.get(endpoint)
    return response?.data
  } catch (e) {
    return {
      error: true,
      message: e.response?.data,
    }
  }
}

export const createMobileBadge = async (APIurl, apiKey, lanId) => {
  const endpoint = `${APIurl}mobile_badges?key=${apiKey}`
  try {
    const response = await Axios.post(endpoint, {
      lan_id: lanId,
    })
    return response?.data
  } catch (e) {
    return {
      error: true,
      message: e.response?.data,
    }
  }
}
