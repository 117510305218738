import '@enterprise-ui/canvas-ui-css'
import Main from './Main.js'
// All of these dependencies are bundled within praxis-scripts
import { AuthProvider } from '@praxis/component-auth'
import { BrowserRouter as Router } from 'react-router-dom'
import apiConfig from './apiConfig.js'

const App = () => {
  return (
    <AuthProvider
      clientId={apiConfig.auth.clientId}
      authorizationUrl={apiConfig.auth.authorizationUrl}
      logoutUrl={apiConfig.auth.logoutUrl}
    >
      <Router>
        <Main />
      </Router>
    </AuthProvider>
  )
}
export default App
