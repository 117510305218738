import { useState } from 'react'
import { DefaultErrorMessage } from '../utils/error-util.js'
import apiConfig from '../apiConfig.js'
import { reactivatePermanentBadge } from '../api/reactivatePermanentBadge.js'

export default function useReactivatePermanentBadge(session) {
  const [reactivateBadgeComplete, setReactivateBadgeComplete] = useState()
  const [isLoadingReactivateBadge, setIsLoadingReactivateBadge] =
    useState(false)
  const [reactivatePermanentBadgeError, setReactivatePermanentBadgeError] =
    useState('')

  const reactivateBadge = async () => {
    setIsLoadingReactivateBadge(true)
    const response = await reactivatePermanentBadge(
      apiConfig.apiUrl,
      apiConfig.apiKey,
      session?.userInfo?.lanId,
    )
    setIsLoadingReactivateBadge(false)
    if (response?.error) {
      setReactivatePermanentBadgeError(DefaultErrorMessage)
      return null
    }
    setReactivateBadgeComplete(true)
  }

  return {
    reactivateBadge,
    reactivateBadgeComplete,
    isLoadingReactivateBadge,
    reactivatePermanentBadgeError,
    setReactivatePermanentBadgeError,
  }
}
