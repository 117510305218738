import Axios from 'axios'

export const LdapAdGroupConsumer = async (APIurl, apiKey, lanId) => {
  const endpoint = `${APIurl}/user/ad_group?lan_id=${lanId}&key=${apiKey}`
  try {
    const response = await Axios.get(endpoint)
    return response?.data
  } catch (e) {
    return {
      error: true,
      message: e.response?.data,
    }
  }
}
