import apiConfig from '../apiConfig.js'
import { useState, useEffect } from 'react'
import { mobileBadgeActivation } from '../api/mobileBadgeActivation.js'
import { UserHasNoMobileBadgeError } from '../utils/error-util.js'
import { LdapAdGroupConsumer } from '../api/ldapAdGroupConsumer.js'

export default function useOnLoadMobileBadge(session) {
  const [userHasMobileBadge, setUserHasMobileBadge] = useState('')
  const [userHasMobileBadgeErrorFlag, setUserHasMobileBadgeErrorFlag] =
    useState(false)
  const [isLoadingUserHasMobileBadge, setIsLoadingUserHasMobileBadge] =
    useState(false)
  const [ldapAdList, setLdapAdList] = useState([])
  const [isLdapLoading, setIsLdapLoading] = useState(false)

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoadingUserHasMobileBadge(true)
        setIsLdapLoading(true)
        const response = await mobileBadgeActivation(
          apiConfig.apiUrl,
          apiConfig.apiKey,
          session?.userInfo?.lanId,
        )
        const LdapResponse = await LdapAdGroupConsumer(
          apiConfig.apiUrl,
          apiConfig.apiKey,
          session?.userInfo?.lanId,
        )
        //prod debug
        console.log(LdapResponse)
        if (LdapResponse?.data) {
          setIsLdapLoading(false)
          setLdapAdList(LdapResponse?.data?.member_of)
        }
        if (LdapResponse?.error) {
          setIsLdapLoading(false)
          setLdapAdList([])
        }
        if (response?.data) {
          setIsLdapLoading(false)
          if (response?.data?.isBadgeActive === true) {
            setIsLoadingUserHasMobileBadge(false)
            setUserHasMobileBadgeErrorFlag(false)
            setUserHasMobileBadge('Exists')
          } else {
            setIsLoadingUserHasMobileBadge(false)
            setUserHasMobileBadgeErrorFlag(false)
            setUserHasMobileBadge('InActiveMobileCred')
          }
        }
        if (response?.error) {
          setIsLoadingUserHasMobileBadge(false)
          setIsLdapLoading(false)
          if (response?.message?.message !== UserHasNoMobileBadgeError) {
            setUserHasMobileBadgeErrorFlag(true)
          }
          if (response?.message?.message === UserHasNoMobileBadgeError) {
            setUserHasMobileBadgeErrorFlag(false)
            setUserHasMobileBadge('NotExists')
          }
        }
      } catch (error) {
        console.error(error)
        // Handle errors here if needed
      }
    }

    fetchData()
  }, [session?.userInfo?.lanId])

  return {
    userHasMobileBadge,
    userHasMobileBadgeErrorFlag,
    setUserHasMobileBadgeErrorFlag,
    isLoadingUserHasMobileBadge,
    ldapAdList,
    isLdapLoading,
  }
}
