import BadgeIcon from '../utils/images/badge.png'

import { HomePageButton } from './homePageButton.js'

const ReactivatePermanentBadgeButton = (props) => {
  return (
    <HomePageButton
      text="activate a plastic badge"
      icon={BadgeIcon}
      iconAlt="Physical plastic badge icon"
      onClick={props.onClick}
    />
  )
}

export { ReactivatePermanentBadgeButton }
