import { useEffect } from 'react'
import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import { CardholderNotice } from './cardholderNotice.js'
import { DownloadProcess } from './downloadProcess'

// import useMobileBadgeActivation from '../hooks/useMobileBadgeActivation.js'

export default function MobileBadge({
  createMobileBadges,
  createMobileBadgeComplete,
  stopCall,
  isLoadingcreateMobileBadge,
}) {
  const [isNewUser, setIsNewUser] = useState(true)

  // const { activateMobile, reactivateMobile } = useMobileBadgeActivation

  useEffect(() => {
    if (createMobileBadgeComplete === true) {
      setIsNewUser(false)
    }
  }, [createMobileBadgeComplete])

  return (
    <>
      <div id="welcomeDiv" className="welcome">
        <div className="center">
          <Grid.Container align="center" justify="center">
            {isNewUser &&
              (isLoadingcreateMobileBadge === false ? (
                <>
                  <Grid.Item>Terms and Conditions</Grid.Item>
                  <Grid.Item className="termsDiv">
                    <p className="paragraph-header">
                      APPLE PAY ACCESS <br></br>PARTICIPATING PROVIDER TERMS AND
                      CONDITIONS
                    </p>
                    <p className="paragraph-p1">
                      The following Participating Provider Terms and Conditions
                      (the “Terms and Conditions”) set forth the terms and
                      conditions governing Your right, as Participating
                      Provider, to use the Apple Pay Technology through Access
                      Service Manager to enable access Transactions. These Terms
                      and Conditions may be modified by Apple Inc. (“Apple”)
                      from time to time by providing You written notice of such
                      modifications either directly or on behalf of Apple. All
                      references to “You” and “Your” in these Terms and
                      Conditions refer to Participating Provider. Apple and
                      Participating Provider are each a “Party” and together the
                      “Parties.”
                    </p>
                    <ul>
                      <li className="li-1">
                        <strong>1. Defined Terms.</strong> Defined terms will
                        have the meanings given to them in Exhibit A (Defined
                        Terms) when used in these Terms and Conditions.
                      </li>
                      <li className="li-1">
                        <strong>2. Term.</strong>These Terms and Conditions will
                        become effective upon the execution of these Terms and
                        Conditions by Participating Provider (“Effective Date”)
                        and will be in full force and effect until terminated as
                        provided in Section 13 (Termination) (“Term”).
                      </li>
                      <li className="li-1">
                        <strong>3. Apple Access Program Guidelines.</strong>
                        Participating Provider will comply with the terms and
                        conditions set forth in the Apple Access Program
                        Guidelines, which may be updated from time to time, and
                        will be provided to Participating Provider by or on
                        behalf of Apple.
                      </li>
                      <li className="li-1">
                        <strong>4. Use Case Support.</strong>In accordance with
                        the Guidelines, Participating Provider will ensure that
                        Provisioned Credentials can be used everywhere physical
                        access credentials can be used in Participating Provider
                        Properties, unless an exception is pre-approved in
                        writing by Apple either directly or indirectly through
                        Access Service Manager.
                      </li>
                      <li className="li-1">
                        <strong>5. User Support.</strong>Participating Provider
                        will ensure that all Users will be offered the ability
                        to use a Provisioned Credential to conduct Transactions
                        at Participating Provider Properties. If a Participating
                        Provider Property has mobile access, it will have mobile
                        access on all readers within the facility to avoid
                        confusion for Users, to the extent reasonably feasible
                        in light of hardware, installation, and supply chain
                        considerations. Target maintains the right to disable
                        mobile access on certain doors for compliance
                        requirements, such as consistent with PCI DSS or SOX
                        standards. Before a person is considered a User, they
                        must agree to the Mobile Physical Access Control User
                        Agreement, which will define appropriate use of the
                        mobile access. Target reserves the right to revoke any
                        or all Users’ Mobile Physical Access Control User
                        Agreement(s) in its sole discretion. Notwithstanding the
                        Program, Participating Provider will on occasion use QR
                        codes on mobile devices to open doors or turn-styles for
                        visitors, people who have lost their badges, or delivery
                        drivers arriving at sortation centers. This option may
                        be available to Users who have been offered the use of
                        Apple Pay Access, as well as users that have not been
                        offered or agreed to the user agreement.
                        <ul style={{ padding: '10px' }}>
                          <li className="li-1">
                            a. For provisioning of Credentials, in accordance
                            with the Apple Access Program Guidelines,
                            Participating Provider will: i. define logic to
                            govern who is eligible to be provisioned with
                            Credentials; ii. authorize Access Service Manager to
                            send data, including Access Service Manager Data in
                            its possession or control, and any other necessary
                            identifiers for Credentials issued by Participating
                            Provider to Apple necessary to provision
                            credentials; and iii. support the activities of
                            Access Service Manager.
                          </li>
                          <li className="li-1">
                            b. Participating Provider will support Users:
                            <ul style={{ padding: '10px' }}>
                              <li className="li-1">
                                i. with provisioning Credentials and using
                                Provisioned Credentials; and
                              </li>
                              <li className="li-1">
                                ii. by ensuring that the level of service (both
                                in quality and the types of transactions that
                                can be supported) provided for Provisioned
                                Credentials is at least on parity with the level
                                of service provided to physical credentials and
                                credentials offered by Non-Apple Access
                                Services.
                              </li>
                            </ul>
                          </li>
                          <li className="li-1">
                            c. Participating Provider will be responsible for
                            the management of the relationship with Users,
                            including being responsible for:
                            <ul>
                              <li className="li-1">
                                i. the decision to approve or deny provisioning
                                of Credentials to an Enabled Device;
                              </li>
                              <li className="li-1">
                                ii. the right to decline the use of a
                                Provisioned Credential to make Transactions
                                (where technically possible to do so);
                              </li>
                              <li className="li-1">
                                iii. the on-going management and operation of
                                Accounts, including whether any Provisioned
                                Credential, should be suspended or deactivated;
                                and
                              </li>
                              <li className="li-1">
                                iv. providing all access services to Users in
                                connection with Provisioned Credentials.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li className="li-1">
                        <strong>6. Marketing and Branding.</strong>{' '}
                        <ul style={{ padding: '10px' }}>
                          <li className="li-1">
                            a. Participating Provider will ensure that the level
                            of user awareness (both in quality and the types of
                            use cases featured) provided by Participating
                            Provider for Provisioned Credentials is at least on
                            parity with the user awareness provided for physical
                            credentials and/or credentials on Non-Apple Access
                            Services.
                          </li>
                          <li className="li-1">
                            b. Participating Provider will market and describe
                            the Program to potential users in accordance with
                            the Apple Access Program Guidelines and the Apple
                            Pay Marketing Guidelines unless an exception is
                            pre-approved by in writing Apple.
                          </li>
                          <li className="li-1">
                            c. Neither Party may issue a press release regarding
                            Participating Provider’s participation in the
                            Program without the other Party’s prior written
                            consent. Participating Provider will give Apple
                            prior written notice (email being sufficient for
                            this purpose) of any material communications to
                            Users regarding major support issues for the Apple
                            Pay Platform.
                          </li>
                          <li className="li-1">
                            d. Participating Provider may use, reproduce, have
                            reproduced, display, and have displayed any of the
                            Apple Marks on a royalty-free basis, solely for the
                            purposes of announcing and promoting the
                            provisioning of Credentials on Enabled Devices at
                            Participating Provider Properties. All such use of
                            the Apple Marks by Participating Provider must be in
                            accordance with the Apple Access Program Guidelines
                            and the Apple Trademark and Copyright Guidelines.
                          </li>
                          <li className="li-1">
                            e. Participating Provider will provide Apple with
                            Participating Provider Marks. Participating Provider
                            hereby grants Apple and its Service Providers that
                            perform obligations on behalf of Apple under these
                            Terms and Conditions, during the Term, a
                            non-exclusive, non-transferable, worldwide,
                            royalty-free, license to use, reproduce, have
                            reproduced, display, and have displayed any of the
                            Participating Provider Marks in connection with the
                            use and display of the Apple Pay Technology for
                            carrying out the purposes of these Terms and
                            Conditions.
                          </li>
                          <li className="li-1">
                            f. Apple will have no obligation to use or display
                            any Participating Provider Mark, on any Apple
                            Products or Apple marketing materials for such Apple
                            Products, but all use of Participating Provider
                            Marks must be in accordance with any guidelines for
                            such use provided by Participating Provider.
                          </li>
                        </ul>
                      </li>
                      <li className="li-1">
                        <strong> 7. Marketing Restrictions.</strong>In no event
                        will Participating Provider promote or advertise the
                        launch of credential services for Non-Apple Access
                        Service using the Apple Access Program Guidelines or the
                        Apple Pay Marketing Guidelines provided by Apple.
                      </li>
                      <li className="li-1">
                        <strong> 7. Marketing Restrictions.</strong>In no event
                        will Participating Provider promote or advertise the
                        launch of credential services for Non-Apple Access
                        Service using the Apple Access Program Guidelines or the
                        Apple Pay Marketing Guidelines provided by Apple.
                      </li>
                      <li className="li-1">
                        <strong>8. Fees.</strong>
                        <ul>
                          <li className="li-1">
                            a. Participating Provider will not charge Users any
                            additional fees related directly to the Provisioned
                            Credential and solely for Users’ participation in
                            the Program.
                          </li>
                          <li className="li-1">
                            b. Participating Provider will pay Access Service
                            Manager (on behalf of Apple) the Apple fees as set
                            out in Exhibit B (Fees).
                          </li>
                        </ul>
                      </li>
                      <li className="li-1">
                        <strong>9. System Changes.</strong> Absent prior written
                        notice to Apple, Participating Provider may not
                        implement changes to its systems, procedures, processes
                        or functionality, which, as the case may be, may
                        reasonably be expected to result in changes to or
                        otherwise impact: (a) the Apple Pay Technology; (b) the
                        manner in which Credentials are provisioned on an
                        Enabled Device, or (c) the manner in which Credentials
                        provisioned to an Enabled Device function or are
                        processed on the Apple Pay Technology (such changes to
                        systems, procedures, processes or functionality are
                        referred as to “System Changes”). In addition, and not
                        by way of limitation, Participating Provider will (i)
                        notify Apple not less than ninety (90) days prior to any
                        System Change that Participating Provider reasonably
                        believes will disable any core functionality of the
                        Apple Pay Technology, or introduce any material
                        additional security exposure to Apple or consumers and
                        (ii) provide support to Access Service Manager to work
                        in good faith with Apple to address any bona fide
                        concerns of Apple with regard to such proposed System
                        Change. If Apple objects to any System Change, the
                        System Change may not go forward until the objection is
                        resolved.
                      </li>
                      <li className="li-1">
                        <strong>10. IntellectualProperty.</strong>
                        <ul>
                          <li className="li-1">
                            a. Each Apple and Participating Provider owns all of
                            their respective Intellectual Property Rights in its
                            Technology conceived, reduced to practice, authored,
                            or otherwise created or developed separately and
                            independently of the other party.
                          </li>
                          <li className="li-1">
                            b. Except as expressly granted under these Terms and
                            Conditions, or otherwise agreed in writing by Apple
                            and Participating Provider, no other rights or
                            licenses to distribute, disclose, sell, adapt,
                            modify, reproduce, copy, publish, display, perform,
                            prepare derivative works, or otherwise use, in any
                            manner, form or media, any of the Technology or
                            Intellectual Property Rights of the other party are
                            granted. Nothing contained in these Terms and
                            Conditions will be construed as constituting a
                            transfer or an assignment by one party to the other
                            party of any of the Technology or Intellectual
                            Property Rights of a party.
                          </li>
                        </ul>
                      </li>
                      <li className="li-1">
                        <strong>11. Compliance with Law.</strong> Each Party
                        will comply with all applicable Laws related to the
                        performance of its obligations under these Terms and
                        Conditions.
                      </li>
                      <li className="li-1">
                        12. Confidentiality.
                        <ul>
                          <li className="li-1">
                            a. Apple will protect Participating Provider Data,
                            including, for the avoidance of doubt, transaction
                            data, from unauthorized dissemination and use with
                            the same degree of care that it uses to protect its
                            own like information. Except to the extent
                            Participating Provider Data is already in Apple’s
                            possession or lawfully and independently obtained by
                            Apple, Apple shall not (i) store any User personally
                            identifiable information or (ii) use or disclose any
                            Participating Provider Data, except for purposes of
                            (A) performing its obligations and exercising its
                            rights under these Terms and Conditions, or (B)
                            improving the Apple Pay Platform and other Apple
                            products or technology used internally by Apple in
                            connection with Apple products. In the case of any
                            use or disclosure of personally identifiable
                            information contained within Participating Provider
                            Data pursuant to clause (ii)(B) above, Apple (1)
                            must de-identify (as to an individual or Enabled
                            Device) such Participating Provider Data, (2) may
                            use or disclose such Participating Provider Data
                            only as so de-identified and in aggregate form, (3)
                            shall not attempt to re-identify the Participating
                            Provider Data or associate any of the Participating
                            Provider Data with any individual or Enabled Device,
                            and (4) must use such Participating Provider Data in
                            a manner that conforms with Apple’s Privacy Policy.
                          </li>
                          <li className="li-1">
                            d. Participating Provider will protect Apple
                            Confidential Information obtained pursuant to these
                            Terms and Conditions from unauthorized dissemination
                            and use with the same degree of care that it uses to
                            protect its own like information. Except as
                            expressly set forth herein, Participating Provider
                            will not use the Apple Confidential Information for
                            purposes other than those necessary to directly
                            further the purposes of these Terms and Conditions.
                            Except as expressly permitted under these Terms and
                            Conditions, Participating Provider will not disclose
                            to third parties the Apple Confidential Information
                            without the prior written consent of Apple,
                            including (i) the public disclosure of any metrics
                            related to the Program and (ii) Participating
                            Provider’s planned participation in the Program
                            prior to the public launch of Participating
                            Provider’s participation in the Program.
                          </li>
                          <li className="li-1">
                            e. To the extent Participating Provider provides any
                            ideas, requests, feedback, reports, suggestions or
                            recommendations to Apple, in writing, orally, by
                            demonstration or otherwise, regarding the Apple
                            Confidential Information, Participating Provider
                            hereby grants to Apple a non-exclusive, fully
                            paid-up, perpetual, irrevocable, worldwide license
                            to make, use, reproduce, incorporate, modify,
                            display, perform, sell, make or have made derivative
                            works of, distribute (directly or indirectly) and
                            sublicense, such feedback in connection with Apple’s
                            products and services.
                          </li>
                          <li className="li-1">
                            f. To the extent Participating Provider receives
                            Apple Provisioning Data from Access Service Manager
                            or directly from Apple, the following provisions
                            will apply:
                            <ul>
                              <li className="li-1">
                                i. Participating Provider shall use the Apple
                                Provisioning Data only for the purposes of
                                ensuring satisfactory performance of its
                                obligations in connection with the provisioning
                                approval of Credentials and implementation
                                process for the Apple Pay Platform.
                              </li>
                              <li className="li-1">
                                ii. Participating Provider agrees to
                                erase/delete the Apple Provisioning Data as
                                immediately following making the provisioning
                                path decision. No storage of the Apple
                                Provisioning Data by Participating Provider is
                                permitted.
                              </li>
                              <li className="li-1">
                                iii. All Apple Provisioning Data to be furnished
                                hereunder is provided “AS IS” and without any
                                warranty, express, implied or otherwise,
                                regarding its accuracy or performance.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li className="li-1">
                        <strong>13. Termination.</strong>
                        <ul>
                          <li className="li-1">
                            a. Apple may suspend or terminate Participating
                            Provider’s participation in the Program in the event
                            of Participating Provider’s breach of any of these
                            Terms and Conditions and such breach is not remedied
                            within thirty (30) days of receiving written notice
                            of such breach by Apple. Participating Provider also
                            acknowledges and agrees that any violation of the
                            requirements set forth in these Terms and Conditions
                            will be grounds for Apple to suspend the
                            provisioning of Credentials to Enabled Devices.
                          </li>
                          <li className="li-1">
                            b. Participating Provider acknowledges and agrees
                            that in the case of the termination of its
                            participation in the Program by Apple or Access
                            Service Manager, these Terms and Conditions will
                            thereby be terminated concurrently without the
                            requirement of further or separate notice, written
                            or otherwise, by Apple.
                          </li>
                          <li className="li-1">
                            c. Participating Provider acknowledges and agrees
                            that in the case of the termination of the Access
                            Service Manager Agreement between Access Service
                            Manager and Apple, these Terms and Conditions will
                            thereby be terminated concurrently without
                            requirement of further or separate notice, written
                            or otherwise, by Apple, unless Apple agrees by
                            written notice that Participating Provider has at
                            that time entered into an agreement between itself
                            and an alternative Access Service Manager acceptable
                            to Apple at its sole discretion.
                          </li>
                          <li className="li-1">
                            d. Participating Provider may terminate these Terms
                            and Conditions for any or no reason upon twelve (12)
                            months prior written notice to Apple.
                          </li>
                        </ul>
                      </li>
                      <li className="li-1">
                        <strong>14. DataBreach.</strong>
                        <ul>
                          <li className="li-1">
                            a. Participating Provider will promptly notify
                            Access Service Manager and Apple if it (i) discovers
                            that any person or entity has breached security
                            measures relating to the Program, or gained
                            unauthorized access to any data related to the
                            Program, including Participating Provider Data,
                            Access Service Manager Data, or Access Service
                            Manager Provisioning Data, (in each such case an
                            “Information Security Breach”) or (ii) receives a
                            written supervisory communication, written guidance
                            or written direction from a Governmental Authority
                            that requires a modification to or 4 suspension of
                            the provisioning of Credentials on Enabled Devices.
                            Upon discovery of an Information Security Breach for
                            which Participating Provider is responsible, the
                            Participating Provider will, at its cost, (A)
                            appropriately investigate, remediate, and mitigate
                            the effects of the Information Security Breach and
                            (B) provide Access Service Manager and Apple with
                            assurances reasonably satisfactory to such parties
                            that appropriate measures have been taken to prevent
                            such Information Security Breach from recurring.
                          </li>
                          <li className="li-1">
                            b. Apple represents that it has provided Access
                            Service Manager with a covenant (i) to notify Access
                            Service Manager if Apple experiences an Information
                            Security Breach relating to the Program and (ii), at
                            its cost, to (A) appropriately investigate,
                            remediate, and mitigate the effects of the
                            Information Security Breach and (B) provide Access
                            Service Manager, and Participating Provider through
                            communication with Access Service Manager, with
                            reasonable assurances that appropriate measures have
                            been taken to prevent such Information Security
                            Breach from recurring. Additionally, if and to the
                            extent any Information Security Breach or other
                            unauthorized access, acquisition or disclosure of
                            User PII (as defined below), Participating Provider
                            Data, Access Service Manager Data, or Access Service
                            Manager Provisioning Data occurs and Access Service
                            Manager reasonably determines that notices or other
                            remedial measures are warranted, Apple has, upon
                            Access Service Manager’s reasonable request, agreed
                            to undertake such notices and remedial actions.
                          </li>
                          <li className="li-1">
                            c. Additionally, if and to the extent any
                            Information Security Breach or other unauthorized
                            access, acquisition or disclosure of Cardholder PII
                            or Apple Provisioning Data occurs and if the other
                            Party reasonably determines that notices or other
                            remedial measures (including notice or the
                            establishment of a call center to respond to
                            customer inquiries) are warranted the Party who is
                            subject to such Information Security Breach will, at
                            its cost and expense, upon the other Party’s
                            reasonable request, undertake such notices and
                            remedial actions.
                          </li>
                        </ul>
                      </li>
                      <li className="li-1">
                        <strong>15 .Unauthorized Transactions. </strong> Apple
                        will not be liable to any party for any
                        Transactioninitiated by a person or party who is not
                        authorized to make a Transaction on an Account,
                        including without limitation any fraudulent Transaction.
                      </li>
                      <li className="li-1">
                        <stron>16. TestingSupport.</stron> Participating
                        Provider must support testing and certification of the
                        Program in accordance with the Apple Access Program
                        Guidelines.
                      </li>
                      <li className="li-1">
                        <strong>
                          17. Parity with Physical Access Credential and other
                          Access Services.{' '}
                        </strong>
                        Participating Provider may not process or decline
                        Transactions, or activate, suspend or cancel Credentials
                        or Accounts, in a manner that discriminates against the
                        Program compared to physical access credentials and
                        Non-Apple Access Services
                      </li>
                      <li className="li-1">
                        <strong>18. Reporting Data </strong>Participating
                        Provider agrees to provide
                        Apple(viaAccessServiceManager)thedataand statistics
                        identified in Exhibit C (Reporting) and in accordance
                        with the Apple Access Program Guidelines (the
                        “Reports”). Apple may use the data and statistics
                        provided by Participating Provider for purposes of (1)
                        performing its obligations and exercising its rights
                        under these Terms and Conditions, or (2) improving the
                        Apple Pay Technology and other Apple Products or
                        technology used internally by Apple in connection with
                        Apple Products.
                      </li>
                      <li className="li-1">
                        <strong>19. PassData</strong> Participating Provider
                        expressly agrees to provide User
                        PII,includingdetailedtransactiondata, directly to
                        Enabled Devices to support in the creation of
                        representations of Credentials in accordance with the
                        Apple Pay Technology implementation and operation
                        guidelines provided from time to time by Apple and
                        according to the User’s preferences to the extent such
                        provision is allowed under applicable Law.
                      </li>
                      <li className="li-1">
                        <strong>20. Representations and Warranties.</strong>
                        Each Apple and Participating Provider represents and
                        warrants that as of the Effective Date:
                        <ul>
                          <li className="li-1">
                            a. it has the requisite power and authority and the
                            legal right to conduct its business as now conducted
                            and hereafter contemplated to be conducted, to enter
                            into these Terms and Conditions and to grant the
                            licenses granted herein;
                          </li>
                          <li className="li-1">
                            b. no legal proceeding or regulatory action is
                            pending or, to its knowledge, threatened against it
                            that would reasonably be expected to have a material
                            adverse effect on its ability to perform its
                            obligations under these Terms and Conditions; and
                          </li>
                          <li className="li-1">
                            c. except as would not reasonably be expected to
                            have a material adverse effect on its ability to
                            perform its obligations under these Terms and
                            Conditions, (i) it is in compliance with all
                            applicable Laws and (ii) it is not subject to any
                            order or ruling that restricts in any respect its
                            ability to perform its obligations under these Terms
                            and Conditions.
                          </li>
                        </ul>
                      </li>
                      <li className="li-1">
                        <strong>21. WARRANTY DISCLAIMER.</strong>
                        EXCEPT AS EXPRESSLY PROVIDED HEREIN, NOPARTY MAKES ANY
                        REPRESENT A TIONS OR W ARRANTIES, EXPRESS OR IMPLIED,
                        INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY OR
                        IMPLIED WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE,
                        ALL SUCH WARRANTIES BEING EXPRESSLY DISCLAIMED. WITHOUT
                        LIMITING THE FOREGOING, APPLE DOES NOT WARRANT THAT THE
                        USE OF THE APPLE PAY TECHNOLOGY OR ANY RELATED PRODUCTS
                        AND SERVICES WILL BE ERROR FREE OR UNINTERRUPTED.
                      </li>
                      <li className="li-1">
                        <strong>22. LimitationofLiability</strong>
                        Exceptfor(a)aParty’sbreachofitsconfidentialityobligationsor(b)theliability
                        of a Party for death or personal injury caused by gross
                        negligence, for fraud or deceit, and in each case, to
                        the extent permitted under applicable Law, in no event
                        will either Party be liable to the other Party for
                        indirect, consequential, incidental, special, punitive
                        or exemplary damages, whether in contract, tort (whether
                        in negligence or strict liability) or other legal or
                        equitable theory, or any loss of profits, revenue,
                        business, production, goodwill, use, data, anticipated
                        savings, equity or share value, or any other loss of an
                        economic advantage, regardless of whether such Party
                        knew or should have known of the possibility of such
                        damages.
                      </li>
                      <li className="li-1">
                        <strong>23. General.</strong>
                        <ul>
                          <li className="li-1">
                            <strong>a. Governing Law.</strong>These Terms and
                            Conditions, any non-contractual obligations and the
                            relationships between the Parties arising out of or
                            in connection with these Terms and Conditions will
                            be governed by, construed, and take effect in
                            accordance with California law, without regard to
                            its choice of law principles. The Convention on
                            Contracts for the International Sale of Goods will
                            not apply to these Terms and Conditions.
                          </li>
                          <li className="li-1">
                            <strong>b. Assignment.</strong>The rights and
                            obligations of the Parties under these Terms and
                            Conditions will be binding upon and inure to the
                            benefit of the Parties’ respective successors,
                            executors and administrators, as the case may be.
                            Neither Party may assign, delegate or otherwise
                            transfer its rights or obligations under these Terms
                            and Conditions without the other Party’s prior
                            written consent.
                          </li>
                          <li className="li-1">
                            <strong>c. Notice.</strong> Any notice required or
                            permitted hereunder will be in writing and delivered
                            to the relevant Party at its address set forth
                            below. Either Party may change its address for
                            receipt of notice by written notice to the other
                            Party in accordance with this Section 23c (Notice).
                            Such notice will be deemed given: upon personal
                            delivery to the appropriate address; or three (3)
                            Business Days after the date of mailing if sent by
                            certified or registered mail; or one (1) Business
                            Day after the date of deposit with a commercial
                            courier service offering next Business Day service
                            with confirmation of delivery. <br></br>Notices to
                            Participating Provider will be provided to the
                            address listed in signature block below. <br></br>
                            Notices to Apple will be provided to{' '}
                            <ol>
                              <li>
                                Apple Inc.
                                <br /> One Apple Park Way <br /> Cupertino,
                                California 95014 <br /> Attention: Vice
                                President, Apple Pay
                              </li>
                              <br></br>
                              <li>
                                Apple Inc. <br />
                                One Apple Park Way Cupertino,
                                <br />
                                California 95014 <br /> Attention: General
                                Counsel
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </li>
                      <li className="li-1">
                        <strong>d. Entire Agreement. </strong>
                        These Terms and Cnditions and any attached exhibits,
                        schedules and addenda, al of which are incorporated into
                        these Terms and Conditions by this reference, constitute
                        the full and completeunderstanding andagreement of the
                        Parties relating to the subject matter hereof and
                        supersede all prior understandings and agreements
                        relating tosuch subject matter. These Terms and
                        Conditions may be modified only: (a) by awritten
                        amendment signed by both parties, or (b) to the extent
                        expressly permitted by these Terms and Conditions (for
                        example, by Apple by notice to You). The Parties have
                        not entered into these Terms and Conditions in reliance
                        on any agreement, representation or warranty which is
                        not made or repeated in these Terms and Conditions.
                      </li>
                      <li className="li-1">
                        <strong> e. Independent Parties. </strong>
                        The Parties hereto are independent contractors under
                        these Terms and Conditions. Nothing in these Terms and
                        Conditions will be construed to create a joint venture,
                        partnership, employer-employee relationship, or agency
                        relationship between the Parties. Neither Party is
                        authorized to represent, bind, obligate, or contract on
                        behalf of the other.
                      </li>
                      <li className="li-1">
                        <strong> f. Third Party Beneficiaries</strong>
                        Any person that is not a Party to theseTerms and
                        Conditions will not have any rights under or in
                        connection with it except where such rights areexpressly
                        granted under these Terms and Conditions.
                      </li>
                      <li className="li-1">
                        <strong>g. Severability.</strong>
                        If any provision of theseTerms and Conditions is found
                        by a court of competent jurisdiction to be unenforceable
                        for any reason, the remainder of these Terms and
                        Conditions will continue in full force and effect tothe
                        maximum extent permitted by law.
                      </li>
                    </ul>
                    <p style={{ padding: '10px' }}>
                      By signing below, Participating Provider confirms that it
                      has read these Terms and Conditions and agrees to be bound
                      thereby. <br></br>
                      <br></br>
                      IN WITNESS WHEREOF, Apple and Participating Provider has
                      executed these Terms and Conditions on the date set forth
                      below.
                    </p>
                    <div class="row">
                      <div class="column">
                        <ul>
                          <li> Entity Address: jeanette.olson@target.com </li>
                          <li> By:JeanetteOlson hramethrOlcon </li>
                          <li>
                            13.37COTI Name:Jeanette Olson Title: VicePresident{' '}
                          </li>
                          <li> Daet: Mar 24, 2023 </li>
                        </ul>
                      </div>
                      <div class="column">
                        <ul>
                          <li>Apple Inc.</li>
                          <li>N a m e : Jennifer Bailey</li>
                          <li> Title: VP, Internet Services</li>
                          <li> Date: 618|2023</li>
                        </ul>
                      </div>
                    </div>
                    <p className="paragraph-header">
                      Exhibit A<br></br>
                      Defined Terms
                    </p>
                    <p>
                      The following terms apply to the Terms and Conditions:
                    </p>
                    <li className="li-1">
                      1. “Access Service Manager” means the party that contracts
                      with Apple and with Participating Provider to support and
                      integrate Credentials with the Apple Pay Technology and
                      Participating Provider Properties according to its
                      agreements with Apple and with Participating Provider.
                    </li>
                    <li className="li-1">
                      2. “Access Service Manager Agreement” means the agreement
                      between Apple and Access Service Manager to support and
                      integrate Credentials with the Apple Pay Technology.
                    </li>{' '}
                    <li className="li-1">
                      {' '}
                      3. “Access Service Manager Data” means all information
                      related specifically to an Account, Credential, and/or
                      User that is obtained, generated or created by or on
                      behalf of such Access Service Manager in connection with
                      Account establishment, processing and maintenance
                      activities, customer service, and transaction data
                      (transaction date, merchant name, amount, industry
                      category, and industry code) that is communicated directly
                      or indirectly to Apple.
                    </li>
                    <li className="li-1">
                      {' '}
                      4. “Access Service Manager Provisioning Data” means data
                      supplied by Access Service Manager to Apple for the
                      purpose of facilitating a Participating Provider’s
                      provisions path decision process.
                    </li>{' '}
                    <li className="li-1">
                      5. “Account” means any account under which a User may
                      initiate a Transaction pursuant to a User Agreement.
                    </li>
                    <li className="li-1">
                      {' '}
                      6. “Affiliate” means any individual or entity that
                      controls, is controlled by, or is under common control
                      with a Party. As used in this definition, the term
                      “control” means the possession, directly or indirectly, of
                      the power to direct or cause the direction of the
                      management and policies on an individual or entity,
                      whether through the ownership of voting securities, by
                      contract, or otherwise. For the avoidance of doubt, but
                      not by way of limitation, the direct and indirect
                      ownership of more than fifty per-cent (50%) of (i) the
                      voting securities or (ii) an interest in the assets,
                      profits, or earnings of an entity will be deemed to
                      constitute “control” of the entity.
                    </li>
                    <li className="li-1">
                      {' '}
                      7. “Apple Access Program Guidelines” refers to the
                      Contactless Access Pass in Apple Wallet guidelines
                      provided by Apple.{' '}
                    </li>
                    <li className="li-1">
                      {' '}
                      8. “Apple Confidential Information” means (i) Apple’s
                      product plans and roadmaps; (ii) these Terms and
                      Conditions; (iii) Apple Metrics; (iv) any and all
                      information related to Apple’s customers or potential
                      customers that is obtained, generated or created by or on
                      behalf of Apple, including any and all information related
                      to the Apple Payment Platform and the Program, and (v) any
                      other information that could reasonably be determined to
                      be confidential, whether or not identified in writing as
                      confidential.
                    </li>
                    <li className="li-1">
                      {' '}
                      9. “Apple Marks” means the Marks owned or licensable by
                      Apple and listed on Exhibit D (Apple Marks).
                    </li>
                    <li className="li-1">
                      10. “Apple Metrics” means any metrics regarding Apple Pay
                      Technology, the provisioning of Credentials on Enabled
                      Devices or the Program.
                    </li>
                    <li className="li-1">
                      {' '}
                      11. “Apple Pay Marketing Guidelines” refers to the
                      guidelines set forth in https://developer.apple.com/apple-
                      pay/marketing/ and
                      https://www.apple.com/legal/intellectual-property/guidelinesfor3rdparties.html.
                    </li>
                    <li className="li-1">
                      12. “Apple Pay Platform” means Apple’s platform that
                      utilizes Apple Technology to enable users to make payments
                      using physical, digital or virtual payment cards,
                      credentials or account access devices and to access other
                      related services using Apple Products designed by or on
                      behalf of Apple or its Affiliates, including to gain
                      access to a physical space or controlled service.{' '}
                    </li>
                    <li className="li-1">
                      13. “Apple Pay Technology” means the Apple Technology that
                      enables users to make payments and access other related
                      services, including accessing a physical space, or
                      authenticating virtually to use (physically, virtually, or
                      otherwise) Participating Provider services, using
                      Apple-designated Apple Products.
                    </li>
                    <li className="li-1">
                      {' '}
                      14. “Apple Products” means any product, service, or
                      Technology distributed under an Apple Mark or used
                      internally by Apple or an Apple Affiliate.
                    </li>
                    <li className="li-1">
                      15. “Apple Provisioning Data” means data supplied by Apple
                      to Access Service Manager for the purpose of facilitating
                      a provision path decision process.
                    </li>
                    <li className="li-1">
                      16. “Apple Technology” means all Technology (and all
                      Intellectual Property Rights therein or thereto) owned by
                      Apple whether Developed, acquired or otherwise obtained
                      prior to, on or subsequent to the Effective Date.
                    </li>
                    <li className="li-1">
                      17. “Apple Trademark and Copyright Guidelines” refers to
                      the guidelines set forth in
                      http://www.apple.com/legal/trademark/guidelinesfor3rdparties.html.
                    </li>
                    <li className="li-1">
                      18.
                      “Credential”meansanydigitalorvirtualcardeligibletobeprovisionedbyAccessServiceManageronbehalf
                      of Participating Provider or a User for the purposes of
                      initiating a Transaction using Apple Pay Technology.
                    </li>
                    <li className="li-1">
                      19.
                      “CurePeriod”hasthemeaningsetforthinSection12.a(Termination).
                    </li>
                    <li className="li-1">
                      20.
                      “Developed”meansinvented,conceived,reducedtopractice,authored,createdorotherwisedeveloped.
                    </li>
                    <li className="li-1">
                      21. “EffectiveDate”hasthemeaningsetforthinSection2(Term).
                    </li>
                    <li className="li-1">
                      22. “Enabled Device” means any Apple Product that has been
                      enabled to store and/or present Provisioned Credentials to
                      conduct a Transaction using Apple Pay Technology.
                    </li>
                    <li className="li-1">
                      23. “Governmental Authority” means any domestic or
                      foreign, federal, state, provincial, municipal or local
                      government, any political subdivision thereof or any
                      entity exercising executive, legislative, judicial,
                      regulatory, or administrative functions of or pertaining
                      to government, regardless of form, including any agency,
                      bureau, court, tribunal, or other instrumentality.
                    </li>
                    <li className="li-1">
                      24.
                      “InformationSecurityBreach”hasthemeaningsetforthinSection13.a(DataBreach).
                    </li>
                    <li className="li-1">
                      25. “Intellectual Property Rights” means all rights
                      (anywhere in the world, whether statutory, common law or
                      otherwise) in and to all (i) patents, utility models and
                      patent applications in any jurisdiction or under any
                      international convention claiming any inventions or
                      discoveries made, developed, conceived, or reduced to
                      practice, including all divisions, divisionals,
                      substitutions, continuations, continuations-in-part,
                      provisionals, and reissues, re-examinations and extensions
                      thereof; (ii) copyrights; (iii) trade secret rights (iv)
                      Confidential Information (defined in the NDA) and other
                      information or data held as proprietary by either Party
                      that qualifies for trade secret protection; (v)
                      semiconductor chip or mask work rights; (vi) design patent
                      or industrial design law, (vii) rights associated with
                      Marks, and (viii) any other intellectual or other
                      proprietary rights of any kind now known or hereafter
                      recognized in any jurisdiction.
                    </li>
                    <li className="li-1">
                      26.
                      “Law”meansanyfederal,state,localorforeignlaw(includingcommonlaw),code,statute,ordinance,rule,
                      regulation, published standard, permit, judgment, writ,
                      injunction, rulings or other legal requirement.
                    </li>
                    <li className="li-1">
                      27. “Marks” means all unregistered and registered
                      trademarks and service marks, common law trademarks and
                      service marks, trade dress, logos, distinguishing guises,
                      slogans, brand names, trade names, d/b/a names, business
                      names, corporate names, product names and other source or
                      business identifiers and the goodwill associated with any
                      of the foregoing and any renewals and extensions of any of
                      the foregoing.
                    </li>
                    <li className="li-1">
                      28.
                      “Non-AppleAccessService”meansanysoftware,otherthantheApplePayTechnology,thatenablestheuse
                      of a digital or virtual card for the purposes of gaining
                      access to a physical space or authenticating to utilize a
                      controlled service on personal electronic devices.
                    </li>
                    <li className="li-1">
                      29. “ParticipatingProvider”means the entity executing
                      these Terms and Conditions.
                    </li>
                    <li className="li-1">
                      30. “Participating Provider Data” means all information
                      related specifically to an Account, Credential and/or User
                      that is obtained, generated or created by or on behalf of
                      Participating Provider in connection with Account
                      establishment, processing and maintenance activities,
                      Participating Provider service and transaction data.
                    </li>
                    <li className="li-1">
                      31. “Participating Provider Properties” means properties
                      in the Territory owned, leased, or controlled by
                      Participating Provider participating in the Program, under
                      Participating Provider’s sole discretion, and that are
                      enabled for mobile access, based on hardware installs or
                      other concerns such as safety and compliance.
                    </li>
                    <li className="li-1">
                      32.
                      “ParticipatingProviderMarks”meanstheMarksownedorlicensablebyParticipatingProviderandlistedon
                      Exhibit E (Participating Provider Marks).
                    </li>
                    <li className="li-1">
                      33. “Party/Parties”hasthemeaningsetforthinthepreamble.
                    </li>
                    <li className="li-1">
                      34.
                      “PII”meanspersonalinformationthatcanbeusedtoidentifyanindividual.
                    </li>
                    <li className="li-1">
                      35. “Program” means the arrangement by which Apple will
                      make the Apple Pay Technology available to Participating
                      Provider and Access Service Manager to the extent required
                      for Participating Provider to execute instructions given
                      by Users via the Apple Pay Technology and under these
                      Terms and Conditions, the applicable agreement between
                      Apple and Access Service Manager, and any User Agreement,
                      in each case for the purpose of enabling Users to use
                      Provisioned Credentials to make Transactions.
                    </li>
                    <li className="li-1">
                      36. “Provisioned Credential” means a Credential that has
                      been provisioned to an Enabled Device so that the Enabled
                      Device may be used in the Program to make Transactions.
                    </li>
                    <li className="li-1">
                      37. “Reports”has the meaning given in Section
                      18(ReportingData).
                    </li>
                    <li className="li-1">
                      38. “ServiceProvider”means any subcontractor,independent
                      contractor or third party service provider engaged to
                      provide a service on behalf of a Party.
                    </li>
                    <li className="li-1">
                      39. “Systems Changes”has the meaning given in
                      Section8(SystemsChanges).
                    </li>
                    <li className="li-1">
                      40. “Technology” means any (i) technology, formulae,
                      algorithms, procedures, processes, methods, techniques,
                      systems, know-how, ideas, creations, inventions and
                      invention disclosures, discoveries and improvements
                      (whether patentable or unpatentable and whether or not
                      reduced to practice), (ii) technical, engineering,
                      manufacturing, product, marketing, servicing, financial,
                      supplier, and other information, research, and materials,
                      (iii) specifications, designs, models, devices,
                      prototypes, schematics, manuals and development tools,
                      (iv) databases, (v) software, content, and other works of
                      authorship, and (vi) tangible embodiments of any of the
                      foregoing, in any form or media whether or not
                      specifically listed in this definition.
                    </li>
                    <li className="li-1">
                      {' '}
                      41. “Term” has the meaning set forth in Section 2 (Term).
                    </li>
                    <li className="li-1">
                      {' '}
                      42. “Territory”means the United States of America.
                    </li>
                    <li className="li-1">
                      43. “Transaction”meansusing an Enabled Device to gain
                      access to a physical space or utilizea service controlled
                      or provided by Participating Provider.
                    </li>
                    <li className="li-1">
                      44. “UserAgreement ”means the agreement between a
                      Participatin Provider and a User(and/oranyreplacement of
                      such agreement), establishing an Account and governing the
                      use of a Credential, in the form set forth in the
                      Guidelines.
                    </li>
                    <li className="li-1">
                      45. “Users” are individuals who have agreed to Target’s
                      Mobile Physical Access Control User Agreement and are
                      authorized to access a Participating Provider Property
                      pursuant to such agreement. Target reserves the right to
                      exclude employees based on compliance with laws or
                      internal policies, or physical safety concerns (e.g.,
                      non-exempt employees using their personal devices or
                      safety concerns in a supply chain building).
                    </li>
                    <li className="li-1">
                      {' '}
                      46. “You/Your”has the meaning given in preamble.
                    </li>
                    <p className="paragraph-header">
                      Exhibit B <br></br>Fees
                    </p>
                    <ul>
                      <li className="li-1">
                        <strong>1. Currency</strong> Fees are denominated in
                        U.S. Dollars (USD).
                      </li>
                      <li className="li-1">
                        <strong>Fees</strong> Participating Provider will pay to
                        Access Service Manager (on behalf of Apple) the Apple
                        Fee of: a. A Fee of $3 per Provisioned Credential per
                        year, where new account provisionings do not include
                        re-provisionings such as suspend, resume, remote wipe,
                        device wipe, transfer from one Enabled Device to another
                        within same iCloud account & logging out of iCloud. This
                        fee will include support for the Credential and
                        acceptance of the Credential by Enabled Devices.
                      </li>
                    </ul>
                  </Grid.Item>
                  <Grid.Item>
                    {stopCall === false ? (
                      <>
                        <Button
                          className="termsApproval"
                          onClick={(event) => {
                            event.preventDefault()
                            createMobileBadges()
                          }}
                          id="termsApproval"
                        >
                          I agree to terms and condition
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid.Item>
                </>
              ) : (
                <></>
              ))}
            {!isNewUser && (
              <>
                <DownloadProcess />
              </>
            )}
          </Grid.Container>
        </div>
      </div>
      <CardholderNotice />
    </>
  )
}
