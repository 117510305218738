import EnterpriseIcon, { BrandIcon } from '@enterprise-ui/icons'
import '../Main.css'

const TruCredHeader = () => {
  return (
    <div className="header">
      <EnterpriseIcon icon={BrandIcon} className="targetLogo" size="5" />
    </div>
  )
}

export default TruCredHeader
